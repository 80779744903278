<template>
  <section id="LoginPage" class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6">
            <login-card />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoginCard from "@/components/cards/LoginCard.vue";

export default {
  name: "LoginPage",
  components: {
    LoginCard
  }
};
</script>

<style scoped></style>
