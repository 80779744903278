<template>
  <div id="LoginCard" class="card rounded">
    <div class="section">
      <div class="card-image">
        <img
          src="../../assets/bos_logo.svg"
          alt="Blushing Owl Studio Icon"
          style="max-width: 180px;"
        />
      </div>
      <div class="card-content">
        <div class="content">
          <p class="title is-4" style="color: black;">
            Welcome to Blushing Owl Studio Admin
          </p>
          <section>
            <b-field label="Username" label-position="inside">
              <b-input
                icon="account-key-outline"
                v-model.lazy="username"
                placeholder="Jhone Doe"
              />
            </b-field>

            <b-field label="Password" label-position="inside">
              <b-input
                icon="lock-outline"
                v-model.lazy="password"
                placeholder="******"
                type="password"
              ></b-input>
            </b-field>
            <b-button
              type="is-primary"
              icon-left="login"
              expanded
              @click.prevent="validateLoginForm"
            >
              Login
            </b-button>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertManager from "@/utils/AlertManager";
import AuthenticationApi from "@/apis/AuthenticationApi";

export default {
  name: "LoginCard",
  data() {
    return {
      username: null,
      password: null
    };
  },
  methods: {
    resetLoginForm: function() {
      this.username = null;
      this.password = null;
    },
    validateLoginForm: function() {
      if (
        this.username &&
        this.username !== "" &&
        this.password &&
        this.password !== ""
      ) {
        this.performLogin();
      } else {
        AlertManager.showErrorSnackbar(
          "Please completed the form before perform login."
        );
      }
    },
    performLogin: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const params = {
        email: this.username,
        password: this.password
      };
      const response = await AuthenticationApi.requestAuthentication(params);
      loadingComponent.close();

      // reset value of both username and password fields
      this.resetLoginForm();
      if (response) {
        // navigate to dashboard
        this.$router.push({ name: "dashboard" });
      }
    }
  }
};
</script>

<style scoped>
.rounded {
  border-radius: 12px;
}
</style>
